import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import '../components/typewriter.css';

import Layout from '../components/Layout';

const IndexPage = ({
  data: {
    allMdx: { edges: postEdges },
  },
}) => (
  <Layout>
    <div className=" bg-orange-200 min-h-screen ">
      <div className="max-w-2xl text-center mx-auto">
        <h1 className=" pt-10 text-6xl   md:text-8xl   font-sans font-thin text-orange-900 text-center">Ryan Bethel</h1>
        <h2 className="typed mx-auto max-w-2xl  text-sm md:text-xl font-typewriter text-center text-gray-800">
          I like solving interesting problems. <br /> I use software and systems to do it.
        </h2>
        <h2 className="inline-block  typewriter mx-auto mb-5 text-sm md:text-xl font-typewriter text-center text-gray-800">
          ...and sometimes I write about it.
        </h2>
        <hr className="border-solid mb-10 border-orange-900" />
      </div>

      <div className=" max-w-4xl mx-auto rounded-xl  ">
        {postEdges.map(post => (
          <div className="pb-10 " key={post.node.fields.slug}>
            <Link
              to={post.node.fields.slug}
              className="m-5 sm:text-xl md:text-2xl  font-bold text-blue-800 hover:text-indigo-800"
            >
              {post.node.frontmatter.title}
            </Link>
            <p className="mx-10 sm:text-xs md:text-sm font-normal text-gray-500">
              {post.node.frontmatter.date} &mdash;{' '}
              {post.node.frontmatter.categories.map((cat, i) => (
                <React.Fragment key={cat}>
                  {!!i && ', '}
                  {cat}
                </React.Fragment>
              ))}
            </p>
            <h3 className="mx-5 text-sm md:text-lg font-light text-gray-800">{post.node.excerpt}</h3>
          </div>
        ))}
      </div>
      <div className="p-2" />
    </div>
  </Layout>
);

export default IndexPage;

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const IndexQuery = graphql`
  query IndexQuery {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MM/DD/YYYY")
            categories
          }
          excerpt(pruneLength: 200)
          timeToRead
        }
      }
    }
  }
`;
